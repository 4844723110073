import Vue from 'vue';
import App from './App';


Vue.config.productionTip = false;


class LookportPlayerWidget {
    constructor(selector){
        this.selector = selector;
    }
    load(){
        const app = new Vue({
            render(h) {
                return h(App, {props: this.$el.dataset});
            },
        });
        app.$mount(this.selector);
    }
}
window.LookportPlayerWidget = LookportPlayerWidget
export default LookportPlayerWidget;
