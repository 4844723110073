<template>
    <div class="lookport-player-widget" v-if="tracks.length">
        <div id="lookport-player"></div>
        <slick ref="slick" class="slick" :options="slickOptions" v-if="loaded">
            <div v-for="track in tracks" :key="track.id+track.type" class="track" @click="selectTrack(track)"
                 :class="{'current-track':currentTrack !== null && currentTrack.id === track.id}">
                <img v-if="track.photo !== null" :src="track.photo" :alt="track.name">
                <span v-text="track.name"></span>
            </div>
        </slick>
    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        components: {
            Slick
        },
        props: {
            videoIds: {
                type: String,
                default: ''
            },
            trackIds: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            currentTrack: null,
            tracks: [],
            loaded: false,
        }),
        computed: {
            trackIdsArray() {
                return this.trackIds ? this.trackIds.split(',').filter(id => id !== '').map(id => Number.parseInt(id)) : [];
            },
            videoIdsArray() {
                return this.videoIds ? this.videoIds.split(',').filter(id => id !== '').map(id => Number.parseInt(id)) : [];
            },
            slickOptions() {
                return {
                    slidesToShow: 4,
                    adaptiveHeight: true,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                arrows: false,
                                centerMode: true,
                                slidesToShow: 3
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                arrows: false,
                                centerMode: true,
                                slidesToShow: 1
                            }
                        }
                    ]
                }
            },
        },
        methods: {
            async selectTrack(track) {
                this.currentTrack = track;
                 if (track.type === 'video') {
                    await window.Lookport("#lookport-player").load(track.id);
                } else {
                    await window.Lookport("#lookport-player").loadTrack(track.id);
                }
            },

            async initTracks() {
                for (const t of this.trackIdsArray) {
                    const res = await fetch('https://lookport.live/api/tracks/' + t);
                    const data = await res.json();
                    this.tracks.push({
                        id: data.id,
                        name: data.name,
                        photo: data.photo ? data.photo.url : false,
                        type: 'track',
                    });
                }
            },
            async initVideos() {
                for (const t of this.videoIdsArray) {
                    const res = await fetch('https://lookport.live/api/broadcast-player/' + t);
                    const data = await res.json();
                    this.tracks.push({
                        id: data.id,
                        name: data.name,
                        photo: data.poster,
                        type: 'video',
                    })
                }
            }
        },
        async mounted(){
            await this.initTracks();
            await this.initVideos();
            await this.$nextTick();
            await this.selectTrack(this.tracks[0]);
            this.loaded = true;
        },
        async destroyed() {
            this.tracks = [];
            await window.Lookport.Player.dispose()
        }
    }
</script>

<style>
    .slick-slide {
        height: auto !important;
    }
</style>

<style scoped lang="scss">
    @import "~slick-carousel/slick/slick-theme.css";
    @import "~slick-carousel/slick/slick.css";


    #lookport-player-widget {
        height: auto;
        width: auto;
    }
    #lookport-player {
        width: 100%;
        height: 60vh;
    }

    .current-track {
        border: white solid 2px !important;
        border-radius: 5px;
    }

    .slick {
        padding-top: 20px;
    }

    .lookport-player-widget {
        margin-top: 30px;
        margin-left: auto;
        background-color: rgba(11, 12, 12, 0.89);
        border-radius: 20px;
        padding: 25px;

        .track {
            cursor: pointer;
            display: inline-block;
            height: 150px;
            border: solid black;
            background-color: white;
            position: relative;

            img {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }

            span {
                position: absolute;
                left: 10px;
                top: 10px;
                z-index: 1;
                font-size: 20px;
                text-shadow: 0 0 5px black;
                color: white;
            }
        }
    }
</style>
